<template>
  <div class="auth-main viewContainer" >
    <v-container class="auth-container pa-4">
      <div class="mt-5">
        <v-btn 
          :to="{ name: 'login' }"
            small
            rounded
            depressed 
            class="no-caps mb-4 "
        >
          <v-icon left>mdi-arrow-left</v-icon>
          {{ $t('back') }}
        </v-btn>
        
            <v-card class="content-tabs-card px-5 py-5 rounded mt-5">
              <ValidationObserver ref="form1" v-slot="{ invalid }">
                <v-form 
                  :model="form1" 
                  @submit.prevent="submit('form1')"
                  autocomplete="off"
                  method="post"
                >
                  <h1 class="text-display-2 font-weight-medium mb-5">{{ $t('registration_user_title') }}</h1>
                  <p class="mb-8 fg--text subtitle-1 text--secondary">{{ $t('registration_user_head') }}</p>
                  <v-alert
                    :value="form1.hasError && !form1.success"
                    type="error"
                    tile
                    class="mb-4"
                  >
                    <span v-if="form1.error == 'registration_validation_error'">{{ $t('server_error') }}</span>
                    <span v-else>{{ $t('correct_errors') }}</span>
                  </v-alert>
                  <v-row >
                    <v-col cols="12" sm="6" class="pt-0 pb-0">
                      <x-text-field
                        v-model="form1.first_name"
                        ref="form1.first_name"
                        id="form1.first_name"
                        :label="$t('first_name')"
                        :placeholder="$t('first_name')"
                        :name="$t('name')"
                        rules="required|min:1|max:32"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" class="pt-0 pb-0"> 
                      <x-text-field
                        v-model="form1.last_name"
                        ref="form1.last_name"
                        id="form1.last_name"
                        :label="$t('last_name')"
                        :placeholder="$t('last_name')"
                        :name="$t('last_name')"
                        rules="required|min:1|max:32"
                      />
                    </v-col>
                  </v-row>
                  <x-text-field
                    type="email"
                    v-model="form1.email"
                    ref="form1.email"
                    id="form1.email"
                    :label="$t('email')"
                    :placeholder="$t('email')"
                    :name="$t('email')"
                    rules="required|max:64|email"
                  />
                  <x-password
                    v-model="form1.password"
                    ref="form1.password"
                    id="form1.password"
                    :label="$t('password')"
                    :placeholder="$t('password')"
                    :name="$t('password')"
                    rules="required|min:8|max:24"
                  />
                  <v-btn color="primary" class="no-caps mt-4" rounded x-large depressed block :loading="form1.loading" :disabled="form1.loading || invalid" type="submit">{{ $t('sign_up') }} <v-icon right>mdi-arrow-right</v-icon></v-btn>
                </v-form>
              </ValidationObserver>
            </v-card>
          
        <!-- <div class="caption mt-8 px-0 px-md-8 text--secondary">
          <i18n path="sign_up_consent">
            <template #terms_and_conditions>
              <router-link :to="{name: 'terms'}" class="text--secondary">{{ $t('terms_and_conditions') }}</router-link>
            </template>
            <template #privacy_policy>
              <router-link :to="{name: 'privacy'}" class="text--secondary">{{ $t('privacy_policy') }}</router-link>
            </template>
            <template #about>
              <router-link :to="{name: 'about'}" class="text--secondary">{{ $t('about') }}</router-link>
            </template>
          </i18n>
        </div> -->
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  data: () => ({
    tab: 'tab-0',
    tabImg: 'about:blank',
    link_register: false,
    affiliate_token: null,
    token_link_register: '',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: [],
    form1: {
      loading: false,
      terms: '',
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      locale: '',
      timezone: '',
      hasError: false,
      error: '',
      success: false
    },
    isInit: false,
    isSignIn: false,
    loading_google: false
  }),
  created () {
    this.form1.locale = Intl.DateTimeFormat().resolvedOptions().locale || this.$i18n.locale
    this.form1.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || null

    if(this.$route.query && this.$route.query.token){
      let settings = { headers: { 'content-type': 'multipart/form-data' } }
      let formData = new FormData()
      formData.append('token', this.$route.query.token)
      this.axios.post('/auth/check-token-register', formData, settings)
      .then(res => {
        if (res.data.status === 'success') {
          //console.log(res.data)
          this.link_register = true
          this.token_link_register = this.$route.query.token
        }
      })
      .catch(err => {
        if (err.response.data.status === 'error') {
          console.log(err.response)
        }
      })
    }

    if(this.$route.query && this.$route.query.affiliate){
      this.affiliate_token = this.$route.query.affiliate
    }

    // let that = this;
    // let checkGauthLoad = setInterval(function () {
    //   that.isInit = that.$gAuth.isInit;
    //   that.isSignIn = that.$gAuth.isAuthorized;
    //   //console.log(that.$gAuth.isInit)
    //   //console.log(that.$gAuth.isAuthorized)
    //   if (that.isInit) clearInterval(checkGauthLoad);
    // }, 1000);
  },
  methods: {
    async submit (formName) {
      // Reset form validation
      this.$refs[formName].reset()

      // Form defaults
      let form = this[formName]
      form.hasError = false
      form.loading = true

      this.$auth.register({
        data: {
          language: this.$i18n.locale,
          first_name: form.first_name,
          last_name: form.last_name,
          email: form.email,
          password: form.password,
          locale: form.locale,
          timezone: form.timezone,
          terms: form.terms,
          token_link_register: this.token_link_register,
          affiliate_token: this.affiliate_token,
        },
        success: function () {
          form.success = true

          this.$auth.login({
            rememberMe: true,
            fetchUser: true,
            data: {
              locale: this.$i18n.locale,
              email: form.email,
              password: form.password,
              remember: true
            },
            success () {
              // Handle redirection
              let redirectTo

              if (parseInt(this.$auth.user().role) == 1) redirectTo = 'admin.backlink-history'
              if (parseInt(this.$auth.user().role) == 2) redirectTo = 'tracking-keyword'
              if (parseInt(this.$auth.user().role) == 3) redirectTo = 'sales.billing'
              
              // Redirect
              this.$router.push({name: redirectTo})
              this.$root.$refs.master.getCredit()
              this.$root.$refs.master.getCurrency()
            }
          })
        },
        error: function (error) {
          let errors = error.response.data.errors || []

          for (let field in errors) {
            this.$refs[formName + '.' + field].applyResult({
              errors: errors[field],
              valid: false,
              failedRules: {}
            })
          }

          if (errors.length === 0) {
            form.hasError = true
          }

          form.loading = false
        }
      })
    }
  },
  watch: {
    tab: function (val) {
      if (window.innerWidth > 760) {
        let index = val.split('-')
        this.tabImg = this.tabImgs[index[1]]
        this.tabImgHeight = this.tabImgDefaultHeight
        this.tabImgAspectRation = 1.7778
      }
    }
  },
}
</script>
<style>
.custom-text-field{
  margin: 0 !important;
  width: 100%;
  max-width: 100% !important;
}
</style>